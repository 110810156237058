<template>
  <v-container>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel active-class="pa-0">
        <v-expansion-panel-header color="primary white-text">
          <span class="header-text">{{ getLabel('group_information') }}</span>
          <template #actions>
            <v-icon class="arrow-icon m-0" color="white">
              $expand
            </v-icon>
          </template>
          <v-btn small class="mr-2 action-icon" :disabled="updatingGroupStatus" @click.stop="toggleState">
            <span>{{ getLabel(group.active ? 'deactivate' : 'activate') }}</span>
          </v-btn>
          <!-- <v-btn small class="mr-2 action-icon" @click.stop="showDeleteMessage">
            <span>{{ getLabel('delete_group') }}</span>
            <v-icon class="keep-icon-upright order-first">mdi-delete</v-icon>
          </v-btn> -->
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row>
              <v-col>
                <p class="title ">{{ getLabel('group_name') }}</p>
                <p>{{ group.name }}</p>
              </v-col>
              <v-col>
                <p class="title">{{ getLabel('group_url') }}</p>
                <a :href="group.link" target="_blank">{{ group.link }}</a>
              </v-col>
            </v-row>
            <v-divider class="mb-2" />
            <v-row>
              <v-col>
                <p class="title">{{ getLabel('creation_date') }}</p>
                <p>{{ group.createdAt }}</p>
              </v-col>
              <v-col>
                <p class="title">{{ getLabel('group_id') }}</p>
                <p>{{ group.id }}</p>
              </v-col>
            </v-row>
            <v-divider class="mb-2" />
            <v-row>
              <v-col>
                <p class="title">{{ getLabel('product_url') }}</p>
                <a v-if="group.product" href="#" @click.stop="goToPimProductPage(group.product_uuid, group.context)">{{ group.product }}</a>
                <span v-else>N/A</span>
              </v-col>
            </v-row>
            <!-- <v-divider class="mb-2" />
            <v-row>
              <v-col>
                <p class="title">{{ getLabel('group_state') }}</p>
                <p>{{ group.active ? 'Actif' : 'Deactif' }}</p>
              </v-col>
            </v-row> -->
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header color="primary white-text">
          <span class="header-text">{{ getLabel('participants_information') }}</span>
          <template #actions>
            <v-icon class="arrow-icon m-0" color="white">
              $expand
            </v-icon>
          </template>
          <v-btn :disabled="crmNotAllowed" small class="mr-2 action-icon" @click.stop="addUsers">
            <span>{{ getLabel('add_user') }}</span>
            <v-icon class="keep-icon-upright order-first">mdi-plus</v-icon>
          </v-btn>
          <v-btn :disabled="collabNotAllowed" small class="mr-2 action-icon" @click.stop="addCollaborateur">
            <span>{{ getLabel('add_collaborateur') }}</span>
            <v-icon class="keep-icon-upright order-first">mdi-plus</v-icon>
          </v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            class="elevation-1 mt-3 d-inline"
            no-data-text="No users"
            fixed-header
            :headers="participantsHeaders"
            :items-per-page="5"
            disable-sort
            :items="users"
          >
            <template #[`item.statusSwitch`]="{ item }">
              <label class="switch" @click="showBlockMessage(item)">
                <input type="checkbox" :v-model="true" :checked="item.participant.status !== 'BLOCKED'" disabled>
                <span class="slider round"></span>
              </label>
            </template>
            <template #[`item.status`]="{ item }">
              <div v-if="item.participant.status !== 'BLOCKED'">Actif {{ item.participant.status === 'INVITED' ? '(désinscrit)' : '(inscrit)' }}</div>
              <div v-else class="font-weight-bold">Bloqué</div>
            </template>
            <template #[`item.nickname`]="{ item }">
              <div class="">{{ `${item.firstname} ${item.lastname}` }}</div>
            </template>
            <!-- <template #[`item.option`]="{ item : user }">
              <div>
                <v-btn class="text-capitalize text-decoration-underline" small :disabled="user.participant.status === 'BANNED' || user.participant.status === 'INVITED'" text @click="warn(user)">Avertir</v-btn>
                <v-btn class="text-capitalize text-decoration-underline" small :disabled="user.participant.status === 'BANNED' || user.participant.status === 'INVITED'" text @click="showBanMessage(user)">Bannir</v-btn>
                <v-btn class="text-capitalize text-decoration-underline" small text @click="showKickMessage(user)">Supprimer</v-btn>
              </div>
            </template> -->
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="showConfirmMessage" max-width="40%">
      <v-card>
        <v-card-title>
          {{ confirmTitle }}?
        </v-card-title>
        <v-card-text>
          {{ confirmText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmAction"
          >
            {{ getLabel('yes') }}
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="showConfirmMessage = false"
          >
            {{ getLabel('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
// import { useRouter } from 'vue2-helpers/vue-router';
import getLabel from '@/utils/labels';
import { requests } from '@/mixins/index';
import { canUseCRM, canUseCollab } from '@/utils/auth';

import store from '@/store';

// const router = useRouter();

const props = defineProps({
  group: { type: Object, default: () => ({}) },
  users: { type: Array, default: () => [] }
});

const emit = defineEmits(['removeUser', 'updateGroup']);

const panel = ref([0, 1]);
const participantsHeaders = [{ value: 'statusSwitch' }, { text: 'Membres', value: 'nickname' }, { text: 'Rôles', value: 'role' }, { text: 'État', value: 'status', class: 'text-capitalize' }, { text: 'Booking Number', value: 'booking_number' }, { text: 'Id Contact', value: 'idContact' }];
const showConfirmMessage = ref(false);
const confirmTitle = ref('');
const confirmText = ref('');
const confirmAction = ref(() => {});
const loadingStateRequest = ref(false);
const updatingGroupStatus = ref(false);

const participantToggleState = user => {
  const newStatus = user.participant.status === 'BLOCKED' ? 'NORMAL' : 'BLOCKED';
  loadingStateRequest.value = true;
  requests.putParticipant({ id: user.participant.id, _version: user.participant._version, status: newStatus }).then(resp => {
    store.state.loading = false;
    loadingStateRequest.value = false;
    user.participant = resp.data;
    showConfirmMessage.value = false;
  }).catch(err => console.log('ERR Update Participant State: ', err));
};

const addUsers = () => {
  const url = `${process.env.VUE_APP_CRM_URL}?brand=${props.group.workspace}&returnTo=hello&helloGroup=${props.group.id}`;

  requests.redirectToDashboard(encodeURIComponent(url));
  // window.location.href = url;
};

const addCollaborateur = () => {
  const url = `${process.env.VUE_APP_COLLABORATORS_URL}?request=hello&helloGroup=${props.group.id}`;
  requests.redirectToDashboard(encodeURIComponent(url));
  // window.location.href = url;
};

const crmNotAllowed = computed(() => !canUseCRM(store.state.userRoles));

const collabNotAllowed = computed(() => !canUseCollab(store.state.userRoles));

const showBlockMessage = user => {
  showConfirmMessage.value = true;
  if (user.participant.status === 'BLOCKED') {
    confirmText.value = getLabel('user_unblock_confirmation_text');
    confirmTitle.value = getLabel('unblock_user');
  } else {
    confirmText.value = getLabel('user_block_confirmation_text');
    confirmTitle.value = getLabel('block_user');
  }

  confirmAction.value = () => participantToggleState(user);
};

const toggleState = async () => {
  updatingGroupStatus.value = true;
  const roomData = await requests.putRoom({ id: props.group.id, _version: props.group._version, active: !props.group.active });
  updatingGroupStatus.value = false;
  emit('updateGroup', { ...props.group, _version: roomData.data._version, active: roomData.data.active });
};

const goToPimProductPage = (product_uuid, context) => {
  // eslint-disable-next-line no-nested-ternary
  const project = context === 'labalaguere' ? context : context === 'vacances' ? 'speedboat' : 'alpha';

  requests.redirectToDashboard(encodeURIComponent(`${process.env.VUE_APP_ENVIROMENT === 'prod' ? `https://${project}.ucpa.com` : `https://recette.${project}.ucpa.com`}/content/product/${product_uuid}`), true);
};

</script>

<style lang="scss" scoped>
.arrow-icon {
  order: 0;
}

.header-text {
  order: 1;
}

.action-icon {
  order: 2;
  flex: 0 1 auto !important;
}

.keep-icon-upright{
  transform: unset!important;
}
.white-text{
  color: white;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(199, 25, 0, 0.32);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(199, 25, 0);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: rgb(199, 25, 0);
}
</style>
